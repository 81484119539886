import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h3>General Information</h3>
    <p>
      On January 20, 2017, the Securities and Exchange Commission filed a
      Complaint in the United States District Court for the District of New
      Jersey (“the Court”) against Dwayne Edwards, Todd Barker, and various
      other individuals and entities, including Oxton Senior Living, LLC and
      several related entities operating in the form of assisted living
      facilities (ALFs). The Court entered, among other orders, an Order
      Appointing Receiver on January 20, 2017 (the “Receivership Order”). Derek
      Pierce of Healthcare Management Partners, LLC has been appointed the
      receiver (the “Receiver”) in this matter over the Receivership Entities
      (as defined in the Receivership Order which can be found{" "}
      <Link to="/wp-content/uploads/2017/02/01-20-17-SEC-v.-Edwards-Case-No.-2-17-cv-00393-Receiver-Order_.pdf">
        in this court document).
      </Link>
    </p>
    <p>
      Inquiries as to the status of the Receivership Entities should be made at
      this website, as it will be the frontline form of communication to the
      various stakeholders of the facilities (including residents of the
      facilities and their families, vendors and creditors, and bondholders) and
      to the general public. As the Receivership progresses, pertinent updates
      will be added to the website to ensure that interested parties stay well
      informed.
    </p>
    If you would like to contact the Receiver, information can be found by{" "}
    <Link to="/contact">clicking here</Link>.
  </Layout>
)

export default IndexPage
